// SupportWindow.js
import React, { useEffect, useRef, useState } from "react";
import { styles } from "./styles.js";
import send from "../../images/send.png";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Message from "../Message/Message.js";
import IDA from "../../../src/images/IDA.svg";
import BETA from "../../../src/images/BETA.svg";


const getPlatform = sessionStorage.getItem("platform_name");
const getUserName = sessionStorage.getItem("user_name");
const getEmail = sessionStorage.getItem("emailid");

// Set getUserName as "IS_stoa" if it's "investor"; otherwise, keep its value
const finalUserName = getUserName ;

// Construct finalInvs with the appropriate getUserName value
const finalInvs = {
  platform: getPlatform,
  getUserName: finalUserName,
  email: getEmail,
};
const stringifiedfinalInvs = JSON.stringify(finalInvs);
console.log("stringifiedfinalInvs", stringifiedfinalInvs);
const client = new W3CWebSocket(
  `wss://www.ask.intainabs.com/ida/${stringifiedfinalInvs}`
);

const SupportWindow = (props) => {
  const [id, setId] = useState("");
  const [messages, setMessages] = useState([
    {
      text: `Type your query here`,
      from: "chatbot",
    },
  ]);
  const [isOverflowAuto, setIsOverflowAuto] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [suggestedMessages, setSuggestedMessages] = useState([
    `What is the average loan balance of loans which are 180+ dq for Test P deal?`,
  ]);
  const isMounted = useRef(true);

  const clearSuggestedMessages = () => {
    setSuggestedMessages([]);
  };

  const sendMsg = (message) => {
    if (message !== "") {
      clearSuggestedMessages();
      setIsTyping(false);
      client.send(
        JSON.stringify(
          // type: "message",
          `${message}`
        )
      );
      setMessages([...messages, { text: message, from: "user" }]);
      document.getElementById("chatInput").value = "";
      setIsTyping(true);

      setTimeout(() => {
        const chatBox = document.getElementById("chatBox");
        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      }, 0);
    }
  };

  const moveToBottom = () => {
    const chatBox = document.getElementById("chatBox");
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
      setIsOverflowAuto(false);
    }
  };

  const handleSuggestedMessageClick = (message) => {
    // Remove the last user message
    const updatedMessages = messages.slice(0, -1);

    // Add the clicked suggested message
    setMessages([...updatedMessages, { text: message, from: "user" }]);
    setSuggestedMessages(updatedMessages);

    // Send the clicked message
    sendMsg(message);
  };

  useEffect(() => {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
    };

    client.onmessage = (message) => {
      const dataFromServer = message.data;
      if (message.type === "message" && isMounted.current) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: dataFromServer, from: "chatbot" },
        ]);
        moveToBottom();
        setIsTyping(false);
      }
    };

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const chatBox = document.getElementById("chatBox");
    const handleScroll = () => {
      const maxScroll = chatBox.scrollHeight - chatBox.clientHeight;
      const scrollPosition = chatBox.scrollTop;

      if (Math.ceil(scrollPosition) + 1 < maxScroll) {
        setIsOverflowAuto(true);
      } else if (Math.abs(scrollPosition - maxScroll) < 2) {
        setIsOverflowAuto(false);
      }
    };

    chatBox.addEventListener("scroll", handleScroll);

    return () => {
      chatBox.removeEventListener("scroll", handleScroll);
    };
  }, [isOverflowAuto]);

  useEffect(() => {
    // Retrieve chat messages from local storage on component mount
    const storedMessages = JSON.parse(sessionStorage.getItem("chatMessages"));
    // Check if storedMessages is null or empty array, then set the initial message
    if (!storedMessages || storedMessages.length === 0) {
      setMessages([
        {
          text: `Type your query here`,
          from: "chatbot",
        },
      ]);
    } else {
      // console.log({ storedMessages });

      setMessages(storedMessages);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    moveToBottom();
  }, [props.visible]);

  const TrackTheInput = (e) => {
    const inputValue = e.target.value;
    if (inputValue.trim() !== "") {
      setIsTyping(false);
    }
  };

  return (
    <div
      className="transition-chat"
      style={{
        ...styles.supportWindow,
        ...{ display: props.visible ? "block" : "none" },
      }}
    >
      <div className="chat-container">
        <div className="content-chat">
          <div className="Asst-Comps">
            <div className="IDA_Icons">
              <img src={IDA} alt="VirtualAsst" />
              <img src={BETA} alt="VirtualAsst" className="beta" />
            </div>
            <h4 className="human">Ida (Virtual Analyst)</h4>
          </div>
          <h4 className="human">
            {/* Converse With Data */}
            {/* <span> (BETA)</span> */}
          </h4>
        </div>

        <div
          className="chatBox"
          id="chatBox"
          style={{
            overflowY: "auto",
            scrollbarWidth: "none",
            paddingBottom: "100px",
          }}
        >
          {messages.map((item, i) => (
            <Message
              key={i}
              user={item.from === "chatbot" ? "chatbot" : "user"}
              message={item.text}
              classs={
                i === 0 ? "left" : item.from === "chatbot" ? "left" : "right"
              }
            />
          ))}
          {isOverflowAuto && (
            <div className="moveToBottomChat" onClick={() => moveToBottom()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-7 h-7 arrowDownChat"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          )}
        </div>

        {isTyping && (
          <div className="typing_channel">
            <div className="typing-indicator">
              <span style={{ marginRight: "4px" }}></span>
              <span style={{ marginRight: "4px" }}></span>
              <span style={{ marginRight: "4px" }}></span>
            </div>
          </div>
        )}

        {messages.length < 2 ? (
          <div className="suggestedMessage">
            {suggestedMessages.map((message, index) => (
              <div
                key={index}
                className="suggestedMessages"
                onClick={() => handleSuggestedMessageClick(message)}
              >
                <div className="suggMsg">{message}</div>
                <div className="suggArrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.2"
                    stroke="currentColor"
                    className="w-3 h-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="dummySize"></div>
        <div className="inputBox">
          <input
            type="text"
            id="chatInput"
            placeholder="Type here"
            autoComplete="off"
            onChange={(e) => TrackTheInput(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMsg(e.target.value);
              }
            }}
          />
          <button
            className="sendBtn"
            onClick={() => sendMsg(document.getElementById("chatInput").value)}
          >
            {/* <img src={send} alt="send" /> */}
            <span className="ask_Btn">ASK</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportWindow;
