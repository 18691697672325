import React, { useState } from "react";
import Arrow_Accor from "../../images/Arrow_Accor.svg";
import "./Accordion.css"; // Optional: add your styles here

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-container">
      <div className="accordion-title" onClick={toggleAccordion}>
        <span className="acc-line-though">{title}</span>
        <span className={`acc-arrow ${isOpen ? "open" : ""}`}>
          <img src={Arrow_Accor} alt="Arrow_Accor" />
        </span>
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Accordion;
